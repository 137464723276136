import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@github/markdown-toolbar-element';
import Rails from '@rails/ujs';
import jQuery from 'jquery';
import globalInitVue from '@/global_init_vue.ts';

import 'bootstrap';

import '../pages/article-form';
import '../pages/markdown-previews';
import '../pages/common';
import '../pages/bootstrap-common';
import '../pages/questoin-utils.ts';

window.jQuery = jQuery;

// NOTE: rails-ujs has already been loaded! を防ぐ
if (!window._rails_loaded) {
  Rails.start();
}
globalInitVue();
