<template>
  <form
    ref="searchForm"
    data-test="search"
    :action="searchPath"
    accept-charset="UTF-8"
    method="get"
    @submit="state.disabled = true"
  >
    <input :value="largeCategoryId" type="hidden" name="l">
    <input :value="middleCategoryId" type="hidden" name="m">
    <input :value="questionSubjectId" type="hidden" name="s">
    <div class="row">
      <div class="col-lg-7">
        <div class="input-group mb-3">
          <div class="input-group-prepend text-xs">
            <div class="input-group-text pl-2 pr-0">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  value="question"
                  name="st"
                  id="st_question"
                  v-model="state.searchTarget"
                >
                <label class="text-sm form-check-label" for="st_question">問題ID</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  value="word"
                  name="st"
                  id="st_word"
                  v-model="state.searchTarget"
                >
                <label class="text-sm form-check-label" for="st_word">フリーワード</label>
              </div>
            </div>
          </div>
          <input class="form-control" type="search" name="word" v-model="state.word">
          <div class="input-group-append">
            <button
              name="button"
              type="submit"
              class="btn btn-dark px-1 px-lg-3"
              :disabled="state.disabled"
            >
              <i class="far fa-search fa-fw"></i>
            </button></div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="mt-2 align-middle">
          <div class="form-group form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value="true"
              v-model="state.noReply"
              name="nr"
              id="nr"
              @change="handleChangeNoReply"
            >
            <label class="form-check-label" for="nr">未回答の質問のみ表示</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import {
  computed, defineProps, reactive, ref,
} from 'vue';
import { globalPostsPath } from '@/lib/rails-routes';

type SearchTarget = 'question' | 'word'

type Props = {
  word: string,
  noReply: boolean,
  searchTarget: SearchTarget,
  largeCategoryId?: number,
  middleCategoryId?: number,
  questionSubjectId?: number,
}

type State = {
  word: string,
  noReply: boolean,
  searchTarget: SearchTarget,
  disabled: boolean,
}

const props = defineProps<Props>();

const state = reactive<State>({
  word: props.word,
  noReply: props.noReply,
  searchTarget: props.searchTarget,
  disabled: false,
});
const searchForm = ref<HTMLFormElement>();
const searchPath = computed(() => globalPostsPath());
const handleChangeNoReply = () => {
  state.disabled = true;
  searchForm.value?.submit();
};
</script>
