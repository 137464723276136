import { vwrap } from '@sonicgarden/vwrap';
import type { App } from 'vue';
import '@fortawesome/fontawesome-pro/js/all';

import DeleteConfirmableButton from './components/DeleteConfirmableButton.vue';
import PostSearchForm from './components/PostSearchForm.vue';
import NotificationDropdownItem from './components/NotificationDropdownItem.vue';
import CorporationLicenseCalculator from './components/CorporationLicenseCalculator.vue';
import MessageToastr from './components/MessageToastr.vue';

const beforeMountCallback = (app: App) => {
  const bugsnagVue = window.Bugsnag?.getPlugin('vue');
  if (bugsnagVue) {
    app.use(bugsnagVue);
  }
};

export default function () {
  vwrap('x-delete-confirmable-button', DeleteConfirmableButton, { beforeMountCallback });
  vwrap('x-post-search-form', PostSearchForm, { beforeMountCallback });
  vwrap('x-notification-dropdown-item', NotificationDropdownItem, { beforeMountCallback });
  vwrap('x-corporation-license-calculator', CorporationLicenseCalculator, { beforeMountCallback });
  vwrap('x-toastr', MessageToastr, { beforeMountCallback });
}
